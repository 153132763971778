export const colors = {
  palette: {
    primary: {
      main: '#E24848',
      contrastText: '#FFFFFF',
    },
    text: {
      secondary: '#FFFFFF',
    },
  },
};
